@import '@we-make-websites/styles-lib/src/config/configuration';
.creatorOfMonth {
  &__wrapper {
    display: block;
    margin-block-end: var(--spacing-xl);
    margin-block-start: var(--spacing-xl);
  }

  &__header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 100%;
    margin-block-end: 4px;
  }

  &__sidebarWrapper {
    display: block;
    max-width: 100%;
  }

  &__creatorName {
    margin-block-start: var(--spacing-2xl);
    padding-inline-start: var(--spacing-xl);
    text-indent: calc(-1 * var(--spacing-xl));
    white-space: pre-line;
  }

  &__profileImage--wrapper {
    border-radius: 146px;
    height: 73px;
    overflow: hidden;
    position: relative;
    width: 73px;
  }

  &__descriptionTag {
    margin-block-end: var(--spacing-m);
  }

  &__mainImageContainer {
    margin-inline-end: calc(-1 * var(--margin-mobile));
    margin-inline-start: calc(-1 * var(--margin-mobile));
    padding-block-end: 100%;
    position: relative;
  }

  &__descriptionBody {
    display: grid;
    grid-auto-flow: column;
  }

  &__logoWrapper {
    height: 85px;
    position: relative;
    width: 85px;
  }

  &__headerTitleTop {
    margin-block-start: 0;
  }

  &__headerTitleBottom {
    text-align: end;
  }

  &__mainImage {
    height: 100%;
    object-fit: cover;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
  }

  &__descriptionText {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-start: var(--spacing-m);
  }

  &__cta {
    margin-block-end: var(--spacing-xl);
    margin-block-start: var(--spacing-l);
  }

  &__subscriptionText {
    margin-block-end: 0;
    margin-block-start: var(--spacing-xl);
    padding-inline-end: 5px;
  }

  &__subscriptionLink {
    color: var(--color-text-primary);
    text-decoration: underline;
  }

  &__desktopComponent {
    display: none;
  }

  @include mq($from: l) {
    &__header {
      display: block;
      max-width: 225px;
    }

    &__creatorName {
      padding-inline-start: var(--spacing-3xl);
      text-indent: calc(-1 * var(--spacing-3xl));
    }

    &__profileImage--wrapper {
      border-radius: 210px;
      height: 105px;
      width: 105px;
    }

    &__sidebarWrapper {
      display: block;
      margin: 0;
      max-width: 275px;
    }

    &__wrapper {
      display: grid;
      margin-block-end: var(--spacing-5xl);
      margin-block-start: var(--spacing-5xl);
    }

    &__descriptionTag {
      margin-block-end: var(--spacing-l);
      margin-block-start: var(--spacing-3xs);
    }

    &__mainImageContainer {
      padding-block-end: 0;
    }

    &__descriptionBody {
      display: block;
    }

    &__logoWrapper {
      height: 115px;
      width: 115px;
    }

    &__headerTitleTop {
      margin: 0;
      margin-block-start: var(--spacing-2xl);
    }

    &__headerTitleBottom {
      margin: 0;
    }

    &__descriptionText {
      margin-block-end: 0;
      margin-block-start: var(--spacing-l);
      margin-inline-start: 0;
    }

    &__cta {
      margin-block-end: var(--spacing-9xl);
      margin-block-start: var(--spacing-l);
    }

    &__mainImage {
      margin-block-end: var(--spacing-6xl);
      margin-block-start: var(--spacing-6xl);
      margin-inline-start: var(--margin-desktop);
      max-height: calc(100% - 150px);
      width: 100%;
    }

    &__subscriptionText {
      margin-block-end: unset;
      margin-block-start: unset;
    }

    &__desktopComponent {
      display: block;
    }

    &__mobileComponent {
      display: none;
    }
  }
}
